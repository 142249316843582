body {
  background-color: whitesmoke;
}

table.readings {
  border: 1px solid #000000;
  border-collapse: collapse;
}
table.readings td, table.readings th {
  border: 1px solid #AAAAAA;
}
table.readings thead {
  background: #DDDDDD;
}
table.readings thead th {
  font-weight: normal;
}
table.readings input {
  padding: 6px;
  border: none;
  background: transparent;
}

.debug {
  font-family: monospace;
}

.app {
  padding: 10px;
}

.flag-debug_day_0 {
  color: #ccc;
}

.flag-below_estimate {
  color: coral;
}

.flag-lower_reading_1 {
  background-color: yellow
}

.flag-lower_reading_2 {
  background-color: yellow
}

.flag-congratulatory {
  color: blue;
}

.date-done {
  background: wheat;
}

.rating {
  min-height: 200px;
  min-width: 300px;
}

.flags {
  min-height: 200px;
  min-width: 300px;
  max-width: 800px;
}

.readings {
  min-width: 450px;
}

.row-container {
  display: flex;
}

.col-container {
  display: flex;
  flex-direction: column;
}

span.overdue {
  color: pink;
}

.readings-box label {
  display: block;
  padding: 4px;
}

.readings-box input {
  margin-left: 10px;
}

.readings-box select {
  margin-left: 10px;
}
